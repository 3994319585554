import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";


export default function Member({ image, name, title, about }) {
  return (
    <Wrapper className="whiteBg shadow">
      <div className="flexSpaceCenter" style={{backgroundImage: `url(${image})`, height:'300px', width:'100%', backgroundSize:'cover', borderRadius: '10px 10px 0px 0px'}}>
      </div>
      <Content>
      <div style={{ margin: "30px 0" }}>
        <h4 className="font30 extraBold">{name}</h4>
        <p className="font13">{title}</p>
      </div>
      <div>
        <p className="font13">{about}</p>
      </div>
      </Content>
      {/* <div style={{ maxWidth: "120px", margin: "30px auto 0 auto" }}>
        <FullButton title="Buy" action={action} />
      </div> */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  margin-top: 30px;
  border-radius: 10px 10px 0px 0px;
`;
const Content = styled.div`  
  padding: 20px 30px;
`;
const MemberImage = styled.img`
  height: 250px;
  margin: 0px auto;
`;