import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border, disabled }) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
      disabled
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#FBB168" : "#3C2C32")};
  background-color: ${(props) => (props.border ? "#FBB168" : "#3C2C32")};
  width: 100%;
  padding: 15px;
  outline: none;
  font-weight: bold;
  color: ${(props) => (props.border ? "#fff" : "#fff")};
  :hover {
    background-color: ${(props) => (props.border ? "#FBB168" : "#3C2C32")};
    border: ${(props) => (props.border ? "1px solid #3C2C32":"1px solid #FBB168")};
    color: ${(props) => (props.border ? "#3C2C32" : "#FBB168")};
  }
`;

