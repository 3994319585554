import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Footer from "../components/Sections/Footer";
import styled from "styled-components";

export default function PrivacyPolicy() {
    return (
        <Wrapper>
            <TopNavbar secondary={true} />
            <div className="container" style={{ paddingBottom: '30px' }}>
                <h1>Aussie Everest Group Pvt. Ltd. Data Retention and Privacy Policy</h1>
                <HeaderInfo>1. Personal Information Collection:</HeaderInfo>
                <p>
                    Aussie Everest Group Pvt. Ltd. is committed to safeguarding your privacy and complying with applicable privacy laws and regulations, including the Privacy Act 1988 (Cth) (Privacy Act). This Data Retention and Privacy Policy outlines how we collect, handle, use, and protect your personal information when you use our hire car booking services. By engaging with our services, you consent to the practices described in this policy.
                    We collect personal information, which includes but is not limited to:<br/>
                    Name <br />
                    Contact information (e.g., email address, phone number)<br />
                    Payment details<br />
                    Travel itineraries<br />
                    Preferences related to your hire car booking.<br />
                </p>
                <HeaderInfo>1. Personal Information Collection:</HeaderInfo>
                <p>Aussie Everest Group Pvt. Ltd. is committed to safeguarding your privacy and complying with applicable privacy laws and regulations, including the Privacy Act 1988 (Cth) (Privacy Act). This Data Retention and Privacy Policy outlines how we collect, handle, use, and protect your personal information when you use our hire car booking services. By engaging with our services, you consent to the practices described in this policy.
                    We collect personal information, which includes but is not limited to:
                    Name,
                    Contact information (e.g., email address, phone number),
                    Payment details,
                    Travel itineraries and
                    Preferences related to your hire car booking.
                </p>
                <HeaderInfo>2. How Do We Collect Personal Information?</HeaderInfo>
                <p>We collect personal information in the following ways:
                    When you make a hire car booking with us, either online, by phone, or through a third-party platform
                    When you provide information to us in person or via email
                    From third parties, such as travel agencies, when they make bookings on your behalf.
                </p>
                <HeaderInfo>3. Why Do We Collect, Use, and Hold Your Personal Information?</HeaderInfo>
                <p>We collect, use, and hold your personal information for the following purposes:
                    To process and manage your hire car bookings.
                    To verify your identity
                    To provide you with information about our hire car services
                    To improve the quality and efficiency of our services
                    To comply with legal obligations and regulatory requirements.
                </p>
                <HeaderInfo>5. How do we protect visitor information?</HeaderInfo>
                <p>We take the following measures to protect your information:
                    Regular scanning of our website for security vulnerabilities
                    Utilization of regular malware scanning
                    Storage of your personal information behind secured networks, accessible only by authorized personnel who maintain confidentiality.
                    Encryption of sensitive/credit information using Secure Socket Layer (SSL) technology
                    Implementation of various security measures when processing orders
                    Processing all transactions through a secure gateway provider, with no storage or processing of sensitive data on our servers.
                </p>
                <HeaderInfo>5. To Whom Will We Disclose Your Personal Information?</HeaderInfo>
                <p>We do not sell your personal information to third parties. However, we may disclose your personal information to the following parties for specific purposes:
                    Car rental companies to facilitate your bookings.
                    Regulatory bodies and government agencies as required by law or court order.
                </p>
                <HeaderInfo>6. Data Retention and Security:</HeaderInfo>
                <p>We will take reasonable steps to ensure that your personal information is:
                    Accurate, complete, and up to date
                    Protected from misuse, interference, loss, and unauthorized access.
                    Destroyed or permanently de-identified when no longer needed for permitted purposes.
                </p>
                <HeaderInfo>7. Do we use 'cookies'?</HeaderInfo>
                <p>Yes, we use cookies, which are small files transferred to your computer's hard drive through your web browser, with your consent. Cookies enable us to:
                    Remember and process items in your shopping cart.
                    Understand and save user preferences for future visits.
                    Track advertisements
                    Compile aggregate data about site traffic and interactions to enhance future site experiences and tools.
                    You can manage cookie preferences through your browser settings. However, disabling cookies may affect certain features and services, but you can still place orders.
                </p>
                <HeaderInfo>8. Third Party Disclosure:</HeaderInfo>
                <p>We do not sell, trade, or otherwise transfer your personally identifiable information to external parties without advance notice. Exceptions include:
                    Website hosting partners and other trusted third parties who assist us in website operations, business activities, or servicing you while agreeing to maintain confidentiality.
                    Compliance with legal requirements, enforcement of site policies, or protection of rights, property, or safety.
                    Non-personally identifiable visitor information may be shared with other parties for marketing, advertising, or other purposes.
                </p>
                <HeaderInfo>9. Marketing:</HeaderInfo>
                <p>We may use your personal information to contact you regarding our hire car services and relevant offers. You can opt out of receiving marketing communications from us at any time by contacting us using the provided contact details.</p>
                <HeaderInfo>10. Links to Other Websites:</HeaderInfo>
                <p>Our website may contain links to other websites. We are not responsible for the data retention and privacy compliance of these external websites. Please review their privacy policies separately.</p>
                <HeaderInfo>11. Google:</HeaderInfo>
                <p>We use Google AdSense Advertising on our website and adhere to Google's Advertising Principles for a positive user experience. Google may use cookies to serve ads based on user visits. Users can opt out of Google's personalized advertising using various methods.</p>
                <HeaderInfo>12. Fair Information Practices:</HeaderInfo>
                <p>We follow the Fair Information Practices Principles, with a commitment to notify users via email within 7 business days in the event of a data breach. We also uphold the individual redress principle, allowing individuals the right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law.</p>
                <HeaderInfo>13. CAN-SPAM Act Compliance:</HeaderInfo>
                <p>We collect email addresses for various purposes, including:
                    Sending information and responding to inquiries
                    Processing orders and providing order-related updates
                    Sending additional information related to products and services.
                    Marketing to our mailing list or continuing to send emails after the original transaction.
                    To comply with the CAN-SPAM Act, we agree to:
                    Not use false or misleading subjects or email addresses
                    Identify messages as advertisements reasonably
                    Include our business or site headquarters' physical address
                    Monitor third-party email marketing services for compliance, if used
                    Honor opt-out/unsubscribe requests promptly, allowing users to unsubscribe via the link at the bottom of each email.
                    If you wish to unsubscribe from future emails, email us or follow the instructions in the email footer, and we will promptly remove you from ALL correspondence.
                </p>
                <HeaderInfo>14. How We Handle Complaints:</HeaderInfo>
                <p>If you have concerns or complaints about the handling of your personal information by AEG, please contact our Data & Privacy Officer via the provided contact information. We will respond to your complaint within a reasonable timeframe and endeavor to resolve it to your satisfaction.
                    If you remain dissatisfied with our response, you may escalate your complaint to the Office of the Australian Information Commissioner for further investigation.
                </p>
                <HeaderInfo>15. Changes to this Policy:</HeaderInfo>
                <p>This Data Retention and Privacy Policy may be updated periodically to reflect changes in privacy laws, industry standards, or our practices. Any updates will be posted on our website, and it is your responsibility to review this policy periodically.
                </p>
                <HeaderInfo>16. Contact us:</HeaderInfo>
                <p>Email us: aussieverest@gmail.com<br/>
                    Phone: 0405481987<br/>
                    Official address: 209/3 Garringarrang Avenue, Kogarah 2217 NSW<br/>
                    <br/><br/>
                    Thank you for choosing Aussie Everest Group Pvt. Ltd. for your hire car booking needs. Your privacy is important to us, and we are committed to maintaining the security and confidentiality of your personal information.
                    <br/><br/>
                </p>
            </div >
            <Footer />
        </Wrapper>
    );
}


const Wrapper = styled.section`
  width: 100%;
  margin: 80px 0px 0px;
`;
const HeaderInfo = styled.h2`
  margin: 10px 0px 0px;
  font-size: 1.3rem;
`;
